import { formErrors } from '@/composables/Form';

import { responseErrorMapWithoutMessage } from '@/axios';
import i18n from '@/i18n';
import store from '@/store';

import { isAxiosError } from '../AxiosHelper';

const { t } = i18n.global;

export function errorHandler(error: any, customMessage?: string, setFormErrors?: (arg: any) => void) {
  if (error.response && error.response.status && Object.keys(responseErrorMapWithoutMessage.value).includes(error.response.status.toString())) return;

  const errorMessage = customMessage ? t(customMessage, { error: error.response?.data.message ?? error.message }) : error.response?.data.message ?? error.message;

  if (isAxiosError(error)) {
    if (error.response?.data.errors) {
      formErrors.value = error.response.data.errors;

      if (setFormErrors) {
        setFormErrors(error.response.data.errors);
      }
    } else {
      store.dispatch.notification.setError(errorMessage);
    }
  } else if (error instanceof Error) {
    store.dispatch.notification.setError(errorMessage);
  } else {
    throw error;
  }
}
