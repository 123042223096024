import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';

const idProp =
  (idName = 'id') =>
  (route: RouteLocationNormalized) => {
    // this function casts the id param to int
    const id = +route.params[idName];
    // console.log("id prop parser, ", id);
    return { ...route.params, [idName]: id };
  };

const routes: Array<RouteRecordRaw> = [
  {
    path: 'dashboard',
    name: 'company.dashboard',
    component: () => import('@/views/company/Dashboard.vue')
  },
  // ------------------ Properties ------------------ //
  {
    path: 'properties',
    name: 'property.index',
    component: () => import('@/views/company/property/Index.vue')
  },
  {
    path: 'properties/residential',
    name: 'property.index.residential',
    component: () => import('@/views/company/property/Index.vue')
  },
  {
    path: 'properties/commercial',
    name: 'property.index.commercial',
    component: () => import('@/views/company/property/Index.vue')
  },
  {
    path: 'properties/misc',
    name: 'property.index.misc',
    component: () => import('@/views/company/property/Index.vue')
  },
  {
    path: 'property/create',
    name: 'property.create',
    component: () => import('@/views/company/property/Create.vue')
  },
  {
    path: 'property/:id',
    component: () => import('@/views/company/property/show/Wrapper.vue'),
    props: idProp(),
    children: [
      {
        path: '',
        name: 'property.show',
        component: () => import('@/views/company/property/show/General.vue')
      },
      {
        path: 'agreements',
        name: 'property.show.agreements.index',
        component: () => import('@/views/company/property/show/Agreements.vue')
      },
      {
        path: 'spaces',
        name: 'property.show.spaces.index',
        component: () => import('@/views/company/property/show/space/Index.vue')
      },
      {
        path: 'space/:spaceId',
        name: 'property.show.spaces.show',
        component: () => import('@/views/company/property/show/space/Show.vue')
      },
      {
        path: 'files',
        name: 'property.show.files.index',
        component: () => import('@/views/company/property/show/Files.vue')
      },
      {
        path: 'maintenance',
        name: 'property.show.maintenance.index',
        component: () => import('@/views/company/property/show/Maintenance.vue')
      },
      {
        path: 'installations',
        name: 'property.show.installation.index',
        component: () => import('@/views/company/property/show/installation/Index.vue')
      },
      {
        path: 'installation/:installationId',
        name: 'property.show.installation.show',
        component: () => import('@/views/company/property/show/installation/Show.vue'),
        props: true
      },
      {
        path: 'log',
        name: 'property.show.log.index',
        component: () => import('@/views/company/property/show/Log.vue')
      },
      {
        path: 'settings',
        name: 'property.show.settings.index',
        component: () => import('@/views/company/property/show/Settings.vue')
      }
    ]
  },
  {
    path: 'properties/vacancy',
    name: 'property.vacancy.index',
    component: () => import('@/views/company/property/Vacancy.vue')
  },
  {
    path: 'properties/reports',
    name: 'property.reports',
    component: () => import('@/views/company/property/Reports.vue')
  },
  {
    path: 'properties/gauges',
    name: 'property.gauges',
    component: () => import('@/views/company/property/GaugeValues.vue')
  },
  {
    path: 'properties/inspections',
    name: 'property.inspection.index',
    component: () => import('@/views/company/property/Inspections.vue')
  },
  {
    path: 'properties/map',
    name: 'property.map',
    component: () => import('@/views/company/property/Map.vue')
  },
  // ------------------ Tenants ------------------ //
  {
    path: 'tenant/',
    name: 'a.tenant.index',
    component: () => import('@/views/company/tenant/Index.vue')
  },
  {
    path: 'tenant/persons',
    name: 'a.tenant.index.persons',
    component: () => import('@/views/company/tenant/Index.vue')
  },
  {
    path: 'tenant/companies',
    name: 'a.tenant.index.companies',
    component: () => import('@/views/company/tenant/Index.vue')
  },
  {
    path: 'tenant/agreements',
    name: 'a.tenant.agreement.index',
    component: () => import('@/views/company/tenant/agreement/Index.vue')
  },
  {
    path: 'tenant/indexation',
    name: 'a.tenant.indexation.index',
    component: () => import('@/views/company/tenant/indexation/Index.vue')
  },
  {
    path: 'tenant/create',
    name: 'a.tenant.create',
    component: () => import('@/views/company/tenant/Create.vue')
  },
  {
    path: 'tenant/:id',
    component: () => import('@/views/company/tenant/show/Wrapper.vue'),
    props: idProp(),
    children: [
      {
        path: '',
        name: 'a.tenant.show',
        component: () => import('@/views/company/tenant/show/General.vue')
      },
      {
        path: 'agreement',
        name: 'a.tenant.show.agreement.index',
        component: () => import('@/views/company/tenant/show/Agreements.vue')
      },
      {
        path: 'mandate',
        name: 'a.tenant.show.mandate.index',
        component: () => import('@/views/company/tenant/show/Mandate.vue')
      },
      {
        path: 'files',
        name: 'a.tenant.show.files.index',
        component: () => import('@/views/company/tenant/show/Files.vue')
      },
      {
        path: 'notes',
        name: 'a.tenant.show.notes.index',
        component: () => import('@/views/company/tenant/show/Notes.vue')
      },
      {
        path: 'account',
        name: 'a.tenant.show.account.index',
        component: () => import('@/views/company/tenant/show/Account.vue')
      },
      {
        path: 'contact',
        name: 'a.tenant.show.contact.index',
        component: () => import('@/views/company/tenant/show/Contact.vue')
      },
      {
        path: 'invoices',
        name: 'a.tenant.show.invoice.index',
        component: () => import('@/views/company/tenant/show/Invoices.vue')
      },
      {
        path: 'balance',
        name: 'a.tenant.show.balance.index',
        component: () => import('@/views/company/tenant/show/Balance.vue')
      }
    ]
  },
  {
    path: 'tenant/create/manually',
    name: 'a.tenant.create.manually',
    component: () => import('@/views/company/tenant/CreateManually.vue')
  },
  {
    path: 'tenant/intake',
    name: 'a.tenant.intake.index',
    component: () => import('@/views/company/tenant/intake/Index.vue')
  },
  {
    path: 'tenant/intake/:id',
    name: 'a.tenant.intake.show',
    component: () => import('@/views/company/tenant/intake/Show.vue'),
    props: idProp()
  },
  // ------------------ Finances ------------------ //
  {
    path: 'finances',
    name: 'finances.overview',
    component: () => import('@/views/company/finances/Overview.vue')
  },
  {
    path: 'finances/rent',
    name: 'finances.rent.index',
    component: () => import('@/views/company/finances/rent/Index.vue')
  },
  {
    path: 'finances/rent/:year/:month',
    name: 'finances.rent.monthly',
    component: () => import('@/views/company/finances/rent/Monthly.vue'),
    props: (to) => {
      const { month, year } = to.params;
      // convert month param to number (and -1 to correctly index months)
      const monthStr = Array.isArray(month) ? month[0] : month;
      const monthNum = +monthStr - 1;

      // convert year param to number
      const yearStr = Array.isArray(year) ? year[0] : year;
      const yearNum = +yearStr;

      return { ...to.params, month: monthNum, year: yearNum };
    }
  },
  {
    path: 'finances/incoming',
    name: 'finances.incoming',
    component: () => import('@/views/company/finances/Incoming.vue')
  },
  {
    path: 'finances/reports',
    name: 'finances.reports',
    component: () => import('@/views/company/finances/Reports.vue')
  },
  {
    path: 'finances/payouts',
    name: 'finances.payouts',
    component: () => import('@/views/company/finances/Payouts.vue')
  },
  {
    path: 'finances/invoices',
    name: 'finances.invoices',
    component: () => import('@/views/company/finances/invoice/Index.vue')
  },
  {
    path: 'finances/invoice/:uuid',
    name: 'finances.invoice.show',
    props: true,
    component: () => import('@/views/company/finances/invoice/Show.vue')
  },
  {
    path: 'finances/transactions',
    name: 'finances.transaction.index',
    component: () => import('@/views/company/Bizcuit/finances/Wrapper.vue')
  },
  {
    path: 'finances/purchase',
    name: 'finances.purchase.index',
    component: () => import('@/views/company/finances/purchase/Purchase.vue')
  },
  {
    path: 'finances/purchase/:id',
    name: 'finances.purchase.show',
    props: true,
    component: () => import('@/views/company/finances/purchase/Show.vue')
  },
  {
    path: 'finances/contact',
    name: 'finances.contact.index',
    component: () => import('@/views/company/finances/contact/Index.vue')
  },
  {
    path: 'finances/contact/:id',
    name: 'finances.contact.show',
    props: true,
    component: () => import('@/views/company/finances/contact/Show.vue')
  },
  {
    path: 'finances/sepa',
    name: 'finances.sepa',
    component: () => import('@/views/company/finances/sepa/Sepa.vue')
  },
  {
    path: 'finances/sepa/:id',
    name: 'finances.sepa.show',
    props: true,
    component: () => import('@/views/company/finances/sepa/Show.vue')
  },
  {
    path: 'finances/costType',
    name: 'finances.costType.index',
    component: () => import('@/views/company/finances/costType/Index.vue')
  },
  {
    path: 'finances/property/costs',
    name: 'finances.property.costs.index',
    component: () => import('@/views/company/finances/property/costs/Index.vue')
  },
  {
    path: 'finances/property/costs/:id',
    name: 'finances.property.costs.show',
    props: true,
    component: () => import('@/views/company/finances/property/costs/Show.vue')
  },
  // ------------------ Maintenance ------------------ //
  {
    path: 'maintenance/assignments',
    name: 'maintenance.assignment.index',
    component: () => import('@/views/company/maintenance/assignment/Open.vue')
  },
  {
    path: 'maintenance/assignment/:id',
    name: 'maintenance.assignment.show',
    component: () => import('@/components/repair/ReportAssignmentShow.vue'),
    props: idProp()
  },
  {
    path: 'maintenance/assignments/closed',
    name: 'maintenance.assignment.closed',
    component: () => import('@/views/company/maintenance/assignment/Closed.vue')
  },
  {
    path: 'maintenance/reports',
    name: 'maintenance.report.index',
    component: () => import('@/views/company/maintenance/report/Index.vue')
  },
  {
    path: 'maintenance/report/:id',
    name: 'maintenance.report.show',
    component: () => import('@/components/repair/ReportAssignmentShow.vue'),
    props: idProp()
  },
  {
    path: 'maintenance/calendar',
    name: 'maintenance.calendar',
    component: () => import('@/views/company/Calendar.vue')
  },
  {
    path: 'maintenance/team',
    name: 'maintenance.team',
    component: () => import('@/views/company/maintenance/Team.vue')
  },
  {
    path: 'maintenance/services',
    name: 'maintenance.services',
    component: () => import('@/views/company/maintenance/services/Index.vue')
  },
  {
    path: 'maintenance/services/:id',
    name: 'maintenance.services.show',
    component: () => import('@/views/company/maintenance/services/Show.vue'),
    props: idProp()
  },
  {
    path: 'maintenance/services/invites',
    name: 'maintenance.services.invites',
    component: () => import('@/views/company/maintenance/services/Invites.vue')
  },
  {
    path: 'maintenance/map',
    name: 'maintenance.map',
    component: () => import('@/views/company/maintenance/Map.vue'),
    meta: { fullscreen: true }
  },
  // ------------------ Misc ------------------ //
  {
    path: 'messages',
    name: 'communication.messages.index',
    component: () => import('@/views/common/messages/Index.vue'),
    props: (route: RouteLocationNormalized) => {
      return { ...route.params, isTenant: false };
    }
  },
  {
    path: 'messages/unread',
    name: 'communication.messages.unread',
    component: () => import('@/views/common/messages/Index.vue'),
    props: (route: RouteLocationNormalized) => {
      return { ...route.params, isTenant: false };
    }
  },
  {
    path: 'messages/archive',
    name: 'communication.messages.archive',
    component: () => import('@/views/common/messages/Index.vue'),
    props: (route: RouteLocationNormalized) => {
      return { ...route.params, isTenant: false };
    }
  },
  {
    path: 'announcements',
    name: 'communication.announcements.index',
    component: () => import('@/views/company/communication/announcements/Index.vue')
  },
  {
    path: 'announcements/drafts',
    name: 'communication.announcements.drafts',
    component: () => import('@/views/company/communication/announcements/Index.vue')
  },
  // {
  //   path: "automations",
  //   name: "communication.automations.index",
  //   component: () =>
  //     import("@/views/company/communication/automations/Index.vue")
  // },
  {
    path: 'settings',
    component: () => import('@/views/company/settings/Wrapper.vue'),
    children: [
      {
        path: '',
        name: 'settings',
        component: () => import('@/views/company/settings/General.vue')
      },
      {
        path: 'users',
        name: 'settings.users',
        component: () => import('@/views/company/settings/Users.vue')
      },
      {
        path: 'agreements',
        name: 'settings.agreements',
        component: () => import('@/views/company/settings/Agreements.vue')
      },

      {
        path: 'finances',
        name: 'settings.finances',
        component: () => import('@/views/common/settings/Finances.vue')
      },
      {
        path: 'branding',
        name: 'settings.branding',
        component: () => import('@/views/company/settings/Branding.vue')
      },
      {
        path: 'maintenance',
        name: 'settings.maintenance',
        component: () => import('@/views/company/settings/Maintenance.vue')
      },
      {
        path: 'legal',
        name: 'settings.legal',
        component: () => import('@/views/company/settings/Legal.vue')
      },
      {
        path: 'removeData',
        name: 'settings.removeData',
        component: () => import('@/views/company/settings/RemoveData.vue')
      },
      {
        path: 'subscriptions',
        name: 'settings.subscriptions',
        component: () => import('@/views/company/settings/Subscriptions.vue')
      },
      {
        path: 'checkups',
        name: 'settings.checkups',
        component: () => import('@/views/company/settings/Checkups.vue')
      },
      {
        path: 'flows',
        name: 'settings.repairflows',
        component: () => import('@/views/company/settings/repairflow/RepairFlows.vue')
      },
      {
        path: 'integrations',
        name: 'settings.integrations',
        component: () => import('@/views/company/settings/Integrations.vue')
      },
      {
        path: 'installations',
        name: 'settings.installations',
        component: () => import('@/views/common/settings/Installations.vue')
      }
    ]
  },
  {
    path: 'settings/flows/:id',
    name: 'settings.repairflows.show',
    component: () => import('@/views/company/settings/repairflow/RepairFlowView.vue'),
    props: idProp()
  },
  {
    path: 'settings/integrations/exact',
    component: () => import('@/views/company/settings/integrations/exact/Wrapper.vue'),
    children: [
      {
        path: '',
        name: 'settings.integrations.exact',
        component: () => import('@/views/company/settings/integrations/exact/General.vue')
      },
      {
        path: 'items',
        name: 'settings.integrations.exact.items',
        component: () => import('@/views/company/settings/integrations/exact/Items.vue')
      },
      {
        path: 'vat',
        name: 'settings.integrations.exact.vat',
        component: () => import('@/views/company/settings/integrations/exact/VATCodes.vue')
      },
      {
        path: 'rentables',
        name: 'settings.integrations.exact.rentables',
        component: () => import('@/views/company/settings/integrations/exact/Rentables.vue')
      },
      {
        path: 'cost',
        name: 'settings.integrations.exact.cost',
        component: () => import('@/views/company/settings/integrations/exact/CostType.vue')
      },
      {
        path: 'remove',
        name: 'settings.integrations.exact.remove',
        component: () => import('@/views/company/settings/integrations/exact/Remove.vue')
      },
      {
        path: 'tenants',
        name: 'settings.integrations.exact.tenants',
        component: () => import('@/views/company/settings/integrations/exact/Tenants.vue')
      }
    ]
  },
  {
    path: 'settings/integrations/bizcuit',
    name: 'settings.integrations.bizcuit',
    component: () => import('@/views/company/Bizcuit/settings/Wrapper.vue')
  },
  {
    path: 'settings/integrations/huurprijscheck',
    name: 'settings.integrations.huurprijscheck',
    component: () => import('@/views/company/settings/integrations/huurprijscheck/Wrapper.vue')
  },
  {
    path: 'company/switch',
    name: 'company.switch',
    component: () => import('@/views/CompanySwitcher.vue')
  },
  {
    path: 'notifications',
    name: 'company.notifications',
    component: () => import('@/views/common/Notifications.vue')
  },
  {
    path: 'account',
    name: 'account',
    component: () => import('@/views/common/Account.vue')
  },
  {
    path: 'search',
    name: 'search',
    component: () => import('@/views/company/Search.vue')
  },
  {
    path: 'contracts/:id',
    name: 'a.contracts',
    component: () => import('@/views/company/contracts/ContractShow.vue'),
    props: idProp()
  },
  {
    path: ':pathMatch(.*)*',
    name: 'NotFoundAuth',
    component: () => import('@/views/NotFound.vue')
  }
];

export default routes;
