import { ExactInternal } from '@/helpers/api/companyEnviorment/ExactLinkTypes';

import { Agreement, AgreementApiData } from './Agreement';
import { HasNotes, Note, Noteable, NoteableModel } from './Noteable';
import Property, { PropertyApiData } from './Property';

export class Space implements Noteable {
  notes: Note[];
  note_type: NoteableModel;
  id: number;
  name: string;
  surface: number | null;
  default_rent: number;
  default_deposit: number;
  default_service_cost: number | null;
  wws: string | null;
  interior: string | null;
  agreements?: Agreement[];
  property: Property | undefined;
  key_number: string | null;
  cost_center?: ExactInternal | null;
  active_agreement: boolean;
  almost_expired_agreement: boolean;

  public get rentFlow(): number | null {
    const addRent = (prev: number | null, curr: Agreement) => (prev !== null && curr.rent !== null ? prev + curr.rent : null);

    // sum all rent values of active agreements
    return this.agreements?.filter((a) => a.isActive).reduce(addRent, 0) ?? null;
  }

  get rental_status(): 'available' | 'almost_expired' | 'rented' {
    if (!this.active_agreement) {
      return 'available';
    } else if (this.almost_expired_agreement) {
      return 'almost_expired';
    } else {
      return 'rented';
    }
  }

  constructor(params: SpaceApiData) {
    this.note_type = 'space';
    this.notes = params.notes ?? [];

    this.id = params.id;
    this.name = params.name;
    this.surface = params.surface;
    // eslint-disable @typescript-eslint/naming-convention
    this.default_rent = params.default_rent;
    // eslint-disable @typescript-eslint/naming-convention
    this.default_deposit = params.default_deposit;
    // eslint-disable @typescript-eslint/naming-convention
    this.default_service_cost = params.default_service_cost;
    this.wws = params.space_details?.wws ?? null;
    this.interior = params.space_details?.interior ?? null;
    this.key_number = params.space_details?.key_number ?? null;
    this.agreements = params.agreements?.map((agreementData) => new Agreement(agreementData));
    this.cost_center = params.cost_center;
    const { property } = params;
    this.property = property ? new Property(property) : undefined;
    this.almost_expired_agreement = params.almost_expired_agreement;
    this.active_agreement = params.active_agreement;
  }
}

export type SpaceApiData = HasNotes & {
  id: number;
  name: string;
  surface: number | null;
  default_rent: number;
  default_deposit: number;
  default_service_cost: number | null;
  agreements?: AgreementApiData[];
  property: PropertyApiData;
  cost_center?: ExactInternal | null;
  almost_expired_agreement: boolean;
  active_agreement: boolean;

  space_details: null | {
    wws: string;
    interior: string;
    key_number: string;
  };
};

export type StoreSpaceForm = {
  name: string;
  surface: number;
  default_rent: number;
  default_service_cost: number;
  default_deposit: number;
  wws: string;
  interior: string;
  key_number: string;
  exact_id: string | undefined;
};

export type UpdateSpaceForm = StoreSpaceForm;

export default Space;
