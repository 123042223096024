<template>
  <div class="mt-1 relative w-full" :class="{ 'rounded-md': hasBorder }">
    <div
      class="w-full"
      :class="[
        hasErrors
          ? `block w-full ${
              hasBorder ? 'border border-red-300' : ''
            } text-red-900 placeholder-red-300 focus-within:outline-none focus-within:ring-red-500 focus-within:border-red-500 `
          : 'block w-full sm:text-sm',
        hasSuccess
          ? 'block w-full border border-green-300 text-green-900 placeholder-green-300 focus-within:outline-none focus-within:ring-green-500 focus-within:border-green-500 '
          : 'block w-full sm:text-sm',
        showOverflow ? '' : 'overflow-hidden',
        {
          'flex items-center border border-gray-200 bg-white rounded-md focus-within:ring-blue-100 focus-within:border-blue-500 focus-within:ring focus-within:outline-none dark:border-gray-600 dark:focus:border-gray-700 dark:text-gray-400 dark:focus-within:ring-gray-800 dark:bg-gray-700':
            hasBorder
        }
      ]"
    >
      <slot v-bind="{ hasErrors, hasSuccess, hasBorder }" />
    </div>
    <div class="z-10 absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
      <svg v-if="hasErrors" class="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
      </svg>
    </div>
  </div>
  <template v-if="hasErrors">
    <p v-for="(error, key) in errors" :id="htmlName + '-error-' + key" :key="error" class="pt-2 text-sm text-red-600 w-full">
      {{ error }}
    </p>
  </template>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';

export default defineComponent({
  name: 'StatusWrapper',
  props: {
    htmlName: {
      type: String,
      required: true
    },
    errors: {
      type: Array as PropType<string | string[]>,
      required: true
    },
    success: Boolean,
    noBorder: Boolean,
    showOverflow: Boolean
  },
  computed: {
    hasErrors(): boolean {
      return this.errors.length !== 0;
    },
    hasSuccess(): boolean {
      return !this.hasErrors && this.success;
    },
    hasBorder(): boolean {
      return !this.noBorder;
    }
  }
});
</script>
