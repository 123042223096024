import { evaluateBooleanEnv, isEnvSet } from '@/helpers/EnvironmentHelper';

export function isLoggerUsed(envVariable: any) {
  const hasUseLoggerEnv = isEnvSet(envVariable);
  const uselogger = evaluateBooleanEnv(envVariable);
  const useLoggerImplicit = !hasUseLoggerEnv && import.meta.env.DEV;

  // use logger when explicitly defined in env variable, or when nothing
  // is specified in .env and NODE_ENV === "development"
  return uselogger || useLoggerImplicit;
}
